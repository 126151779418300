<template>
	<div>
		<div class="mb20 pageTitle"><b>提润</b></div>
		<div v-loading="loading">
			<el-form :label-position="labelPosition" ref="ruleForm" :model="form" :rules="rules" :label-width="labelWidth" class="form">
				<!-- <el-form-item label="商户号:">
					{{userInfo.business_no}}
				</el-form-item> -->
				<!-- <el-form-item label="可用余额:">
					{{userInfo.money | tofixed}}
				</el-form-item> -->
				<el-form-item label="可用余额:">
					<span v-for="item in currencyListMoney" :key="item.cur_id">{{item.cur_name}}:{{item.money}}&emsp;</span>
					<!-- {{userInfo.money | tofixed}} -->
				</el-form-item>
				<el-form-item>
					<span class="cwarning">非常重要：所填信息必须真实有效，否则操作无法成功！</span>
				</el-form-item>
				<!-- <el-form-item label="账户类型:">
					个人账户
				</el-form-item> -->
				<el-form-item label="币种" prop="cur_id">
					<el-radio-group v-model="form.cur_id">
						<el-radio v-for="item in currencyListMoney" :key="item.cur_id" :label="item.cur_id">{{item.cur_name}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="是否转USDT代付" prop="change_usdt"  v-if="form.cur_id != 1">
					<el-checkbox v-model="form.change_usdt">是</el-checkbox>
				</el-form-item>
				<template v-if="form.cur_id != 1 && !form.change_usdt">
					<el-form-item label="收款户名:"  prop="name">
						<el-input style="width: 300px;"  class="mr20" placeholder="请输入收款户名" v-model="form.name" clearable></el-input>
					</el-form-item>
					<el-form-item label="收款账号:" prop="card">
						<el-input style="width: 300px;"  class="mr20" placeholder="请输入收款账号" v-model="form.card" clearable></el-input>
					</el-form-item>
					<el-form-item label="开户行:" prop="bank">
						<el-input style="width: 300px;"  class="mr20" placeholder="请输入开户行" v-model="form.bank" clearable></el-input>
					</el-form-item>
				</template>
				<template v-else>
					<el-form-item label="USDT代付地址:" prop="usdt_address">
						<el-input style="width: 300px;"  class="mr20" placeholder="请输入USDT代付地址" v-model="form.usdt_address" clearable></el-input>
					</el-form-item>
				</template>
				<el-form-item label="金额(元):" prop="money">
					<el-input-number style="width: 300px;" controls-position="right"  class="mr20" placeholder="请输入付款金额" v-model="form.money" clearable></el-input-number>
					<span class="cdanger">(温馨提示：为避免资金退回，请以商户最高额度提现。单笔最高{{maxmoney}}元)</span>
				</el-form-item>
				<el-form-item label="谷歌验证码:" prop="code">
						<el-input style="width: 300px;" controls-position="right"  class="mr20" placeholder="请输入谷歌验证码" v-model="form.code" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="mr20" @click="sure">提交申请</el-button>
					<span class="cinfo">( 注：实际到账时间请以银行时间为准 )</span>
				</el-form-item>

			</el-form>

		</div>
	</div>
</template>

<script>
	import local from '../../util/local.js'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isAllNumber
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				form:{
					cur_id:'',
					change_usdt:false,
					name:'',
					card:'',
					bank:'',
					money:'',
					usdt_address:'',
					code:''
				},
				rules: {
					cur_id: [
						{ required: true, message: '请输入币种', trigger: 'blur' },
					],
					name: [
						{ required: true, message: '请输入持卡人姓名', trigger: 'blur' },
					],
					card: [
						{ required: true, message: '请输入卡号', trigger: 'blur' },
					],
					bank: [
						{ required: true, message: '请输入银行名称', trigger: 'blur' },
					],
					money: [
						{ required: true, message: '请输入金额', trigger: 'blur' },
					],
					usdt_address: [
						{ required: true, message: '请输入USDT代付地址', trigger: 'blur' },
					],
					code: [
						{ required: true, message: '请输入谷歌验证码', trigger: 'blur' },
					],
				},
				loading:false,
				labelPosition: 'right',
				userInfo: {},
				username: local.get('shusername'),
				labelWidth: '120px',
				innerVisible: false,
				innerloading: false,
				dialogloading: false,
				qrcode: '',
				name:'',
				card:'',
				bank:'',
				money:'',
				code:'',
				payway:'',
				paywayArr:[],
				maxmoney:49999,
				currencyListMoney:[]
			}
		},
		created() {
			// this.getInfo()
			// this.paywayList()
			// 币种list 
			this.currencyList()
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			currencyList(){
				this.$api.userMoney({}).then(res=>{
					if (res.status == 1) {
						this.currencyListMoney = res.data
						if(res.data.length > 0){
							this.form.cur_id = res.data[0].cur_id
						}
					}
				})
			},
			//查询基本信息
			getInfo() {
				// this.dialogloading = true
				this.$api.userInfo({}).then(res => {
					// this.dialogloading = false;
					if (res.status == 1) {
						this.userInfo = res.data
					}
				}).catch(error => {
					// this.dialogloading = false
				})
			},
		
			//通道列表
			paywayList() {
				// this.dialogloading = true
				this.$api.paywayList({}).then(res => {
					if (res.status == 1) {
						this.paywayArr = res.data
					}
				}).catch(error => {
				})
			},
		
			sure() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.loading = true;
						let obj = JSON.parse(JSON.stringify(this.form))
						obj.change_usdt = obj.change_usdt?1:2
						// if(this.form.cur_id != 1 && !this.form.change_usd){
							// delete obj.name
							// delete obj.card
							// delete obj.bank
						// }else{
						// 	delete obj.usdt_address
						// }
						this.$api.userMentionMoney(obj).then(res=>{
							this.loading = false;
							if(res.status == 1){
								this.$refs['ruleForm'].resetFields();
								this.$router.push({path:'/tirunOrder'})
								this.$message({type:'success',message:res.msg})
								this.getInfo()
							}else{
								this.$message({type:'error',message:res.msg})
							}
						}).catch(error=>{
							this.loading = false;
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				
					
				// let data = {
				// 	name:this.name,
				// 	card:this.card,
				// 	bank:this.bank,
				// 	money:this.money,
				// 	code:this.code,
				// 	payway_type_id:this.payway,
				// }
				// this.loading = true;
				// this.$api.mentionMoney(data).then(res=>{
				// 	this.loading = false;
				// 	if(res.status == 1){
				// 		this.name = '';
				// 		this.card = '';
				// 		this.bank = '';
				// 		this.money = '';
				// 		this.code = '';
				// 		this.payway = '';
				// 		this.$router.push({path:'/tirunOrder'})
				// 		this.$message({type:'success',message:res.msg})
				// 		this.getInfo()
				// 	}else{
				// 		this.$message({type:'error',message:res.msg})
				// 	}
				// }).catch(error=>{
				// 	this.loading = false;
				// })
			},
		}
	}
</script>



<style lang="scss">
	@import '@/style/variables.scss';

	.pageTitle {
		background-color: $tableBorderColor;
		padding: 10px;
	}

	.form {
		// width: 500px;
		input{
			// width: 200px;
		}
	}

	.qrcode {
		// display: inline-block;
		width: 100%;
		height: 100%;
		text-align: center;

		img {
			max-width: 100%;
			background-color: #fff; //设置白色背景色
			box-sizing: border-box;
		}
	}
</style>
